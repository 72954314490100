import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CardActions, Button, Divider } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import "./style.css";
import Axios from "axios";
import { NotificationManager } from "react-notifications";

let buyerId;

const CompanyHandler = () => {
    const [inputValue, setInputValue] = useState("");


    const updateHandler = async () => {
        const updatedBuyer = await Axios.patch(
            `${process.env.REACT_APP_BACKEND_API}/buyers/update-one-company/${buyerId}`,
            {
                company: inputValue,
            },
        );
        if (updatedBuyer.status === 200) {
            setInputValue(updatedBuyer.data.buyer.company);
            NotificationManager.success("Submit data success");
        } else {
            NotificationManager.error("Submit data failed");
        }
    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            buyerId = JSON.parse(token).id;
        }
        const fetchData = async () => {
            const result = await Axios.get(
                `${process.env.REACT_APP_BACKEND_API}/buyer/${buyerId}`
            );
            const buyer = result.data.buyer;
            setInputValue(buyer.company);
        };
        fetchData();
    }, [buyerId]);

    return (
        <Container>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>Company</Title>
                <SubTitle>Update Company</SubTitle>
            </div>

            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "10px",
                }}
            />

            <Divider />
            <CardActions
                style={{ paddingLeft: "0px", position: "absolute", bottom: 0 }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={updateHandler}
                >
                    Update
                </Button>
            </CardActions>
        </Container>
    );
};

export default CompanyHandler;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 20px 10px;
  height: 282px;
  position: relative;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
`;

const Title = styled.span`
  color: #263238;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.05px;
`;
const SubTitle = styled.span`
  color: #546e7a;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.05px;
`;
